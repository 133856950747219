import React from "react";
import LayoutWrapper from "../../component/LayoutWrapper/Index";
import BlogsPosts from "../../component/Blog/BlogPost/index";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ShareLinks from "../../component/Blog/ShareLinks";
import AuthorAvatar from "../../component/Blog/AuthorAvatar";
import CommonParagraph from "../../component/Blog/CommonParagraph";
import Heading from "../../component/Blog/Heading";
import BlogLink from "../../component/Blog/BlogLink";
import { blogList } from "../../data/blogs";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ContactSlideoutLink from "../../component/Blog/ContactSlideoutLink";

const BlogPost = () => {
  const blogData = blogList.filter(
    (blog) => blog.url == "/condo-vs-single-family-home-whats--right-for-you-in-san-diego"
  );

  return (
    <LayoutWrapper
      title={blogData[0].title}
      desc={blogData[0].metaDescription}
      headerLogo="../../images/Selby_logo_v2.svg"
      headerId="header_v2"
    >
      <div className="pt-[130px] pb-[80px] md:pt-[170px] md:pb-[70px] sm:pt-[30px] 2xs_extar:pb-[50px]">
        <div className="container">
          <div className="max-w-[800px] w-full mx-[auto]">
            <h1 className="text-d-3xl text-[#020101] font-IbarraRealNova font-[600] mb-[32px] sm:text-d-xl sm:mb-[]">
              {blogData[0].title}
            </h1>
          </div>
          <div className="flex justify-between items-center max-w-[800px] w-full mx-[auto] mb-[40px] 2xs_extar:flex-col  2xs_extar:justify-start 2xs_extar:items-start 2xs_extar:gap-[20px]">
            <div className="flex gap-[14px]">
              <AuthorAvatar />
              <div className="">
                <a
                  href={blogData[0].authorUrl && blogData[0].authorUrl}
                  className="font-Quicksand text-[#1A1A1A] text-d-2lg font-[600] mb-[4px]"
                >
                  By {blogData[0].author}
                </a>
                <p className="font-Quicksand text-d-2lg font-[400] text-[#666666]">
                  {blogData[0].date}
                </p>
              </div>
            </div>
            <div className="flex gap-[24px]">
              <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
            </div>
          </div>

          <div className="">
            <StaticImage
              src="../../images/The Selby Team_Condo vs. Single-Family Home_ What's Right for You in San Diego_.jpg"
              alt="blogpost"
            />
            <div className="max-w-[800px] w-full mx-[auto] py-[80px] 2xs_extar:py-[30px]">
              <CommonParagraph>
                Are you thinking about buying a home in San Diego but feeling stuck in the condo vs
                house debate? You’re not alone. Choosing between the two is a big decision that
                hinges on several factors, including your finances, lifestyle choices, and long-term
                goals.{" "}
              </CommonParagraph>
              <CommonParagraph>
                To help you make an informed decision, let’s explore each property's key benefits
                and limitations.{" "}
              </CommonParagraph>
              <Heading type="h2">Buying a Condo: The Benefits</Heading>
              <Heading type="h3">Price</Heading>
              <CommonParagraph>
                Condos are generally cheaper than single-family homes, which makes them
                attractive—especially in competitive markets. Currently, the median listing price
                for a San Diego condo is $660,000, compared to the $980,000 median price tag of a
                single-family home.
              </CommonParagraph>
              <Heading type="h3">Less Maintenance</Heading>
              <CommonParagraph>
                As a tradeoff for paying monthly HOA fees, condo owners do not have to maintain the
                exterior of their homes, cut the grass, or handle landscaping tasks. This can appeal
                to busy buyers who want to own a property—just not maintain it.
              </CommonParagraph>
              <Heading type="h3">More Amenities</Heading>
              <CommonParagraph>
                In general, condos come with many amenities that single-family homes cannot compete
                with. These may include gyms, pools, tennis courts, and clubhouses—all of which
                could save owners gym and entertainment costs.
              </CommonParagraph>
              <Heading type="h3">Community</Heading>
              <CommonParagraph>
                Buying a condo may be an ideal option if you enjoy social interaction and crave
                connection. Many condo communities organize events that bring residents together.
                This can be ideal for singles, empty-nesters, and even couples who simply prefer
                more social interaction.
              </CommonParagraph>
              <Heading type="h2">Buying a Condo: The Downsides</Heading>
              <Heading type="h3">HOA Fees</Heading>
              <CommonParagraph>
                One important factor to consider when buying a condo is monthly HOA fees. These can
                vary greatly from one complex to another. For example, an HOA fee for a $6 million
                property could be $1,600 per month, while a $650,000 condo might have a monthly HOA
                fee of $850. It is also worth noting that HOA fees generally increase over time.
              </CommonParagraph>
              <Heading type="h3">Less Control</Heading>
              <CommonParagraph>
                In general, buying a condo means that you have less control over your surroundings.
                While you are free to paint and upgrade the interior of your home, you must adhere
                to HOA rules and regulations. These rules can regulate everything from pets and
                parking to exterior paint colors, decorations, and more.
              </CommonParagraph>
              <Heading type="h3">Rental Restrictions</Heading>
              <CommonParagraph>
                While rules and regulations vary with each community, many condo HOAs do not allow
                owners to rent their homes. This could be limiting for some owners who want to
                generate rental income or who unexpectedly need to relocate.
              </CommonParagraph>
              <Heading type="h3">Shared Spaces</Heading>
              <CommonParagraph>
                Whether you need total quiet or want the freedom to make noise whenever you like,
                condo life may not be an ideal fit for you. Generally speaking, most condo owners
                share walls or ceilings with their neighbors, meaning you will likely hear your
                neighbors and have to be more mindful of your noise levels.
              </CommonParagraph>
              <Heading type="h2">Buying a Single-Family Home: The Benefits</Heading>
              <Heading type="h3">More Privacy</Heading>
              <CommonParagraph>
                Because single-family homes are detached, they typically offer more privacy than a
                condo. This privacy provides two benefits. First, it means a quieter living
                environment. Second, it gives you more freedom to make noise without disturbing
                others.
              </CommonParagraph>
              <Heading type="h3">Access to Outdoor Spaces</Heading>
              <CommonParagraph>
                Most single-family homes have private yards. While you will have to maintain these
                spaces, the tradeoff is that you can customize this area and use it as you wish.
                Condo owners do not have this degree of privacy.
              </CommonParagraph>
              <Heading type="h3">Greater Control</Heading>
              <CommonParagraph>
                Want to paint the exterior of your home, build a new deck, add a fire ring to the
                backyard, or plant trees? Go for it. Unlike condo owners, single-family homebuyers
                do not need to gain HOA approval to improve exterior spaces.
              </CommonParagraph>
              <Heading type="h3">Greater Flexibility</Heading>
              <CommonParagraph>
                In general, buying a single-family home offers more flexibility. If you decide to
                move or want to rent your home to build passive income, you can do so at any time.
              </CommonParagraph>
              <Heading type="h2">Buying a Single-Family Home: The Limitations</Heading>
              <Heading type="h3">More Maintenance</Heading>
              <CommonParagraph>
                Trust us—owning a home means investing significant time and money to maintain it.
                From regular tasks like cleaning gutters and painting to unexpected repairs like
                fixing a leaky faucet or replacing a broken appliance, something always needs
                attention.
              </CommonParagraph>
              <Heading type="h3">Higher Insurance Costs</Heading>
              <CommonParagraph>
                In general insurance for a single-family home is more expensive than it is for a
                condo. That’s because home insurance covers the entire house; condo insurance
                typically only covers the unit’s interior, personal property, and liability.
              </CommonParagraph>
              <Heading type="h3">More Time Spent on Upkeep</Heading>
              <CommonParagraph>
                At first glance, maintaining your home's interior and exterior may not seem like a
                big deal. However, many new homeowners are often surprised by the time commitment
                required for exterior maintenance—not to mention the cost of purchasing building
                materials and tools.
              </CommonParagraph>
              <Heading type="h2">Ready to Make Your Move? The Selby Team Can Help.</Heading>
              <CommonParagraph>
                Whether you’re considering condo life or are looking for a single-family home, we
                can help you find it. With our extensive knowledge of the San Diego housing market,
                we’re here to bring you to the doorstep of your dream home.{" "}
                <ContactSlideoutLink text="Contact The Selby Team" /> to get started on finding your
                new home.
              </CommonParagraph>
            </div>
            <div className="">
              <div className="flex justify-center gap-[24px]">
                <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutWrapper>
  );
};

export default BlogPost;
